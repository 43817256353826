<template>
  <v-menu
        left
        bottom
        offset-y
  >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            icon
            color="red"
            v-bind="attrs"
            v-on="on"
          >
          <img 
          v-if="photoImg"
          :src="photoImg"
          />
          <div
          v-else
          >
          <span
          v-if="initname"
          class="white--text text-h5"
          >
          {{initname}}
          </span>

          <v-icon
          v-else
          >
            mdi-account
          </v-icon>

        </div>
          </v-avatar>
        </template>

        <v-list>
          <v-list-item 
          v-if="!isProd"

          @click="$store.dispatch('setMessage',{msg:'snack'})">
            <v-list-item-icon class="mr-1">
              <v-icon color="red">
                mdi-exit-to-app
              </v-icon>
            </v-list-item-icon>
            snack
          </v-list-item>


          <v-list-item 
          @click="$store.dispatch('user/logout')">
            <v-list-item-icon class="mr-1">
              <v-icon color="pink">
                mdi-exit-to-app
              </v-icon>
            </v-list-item-icon>
            logout
          </v-list-item>

        <v-list-item
          v-if="!isProd"
          @click="test"
          >
          test
        </v-list-item>

          <v-list-item
          v-if="!isProd"
          @click="test2"
          >
          test2
        </v-list-item>
          <!-- <v-list-item
            v-for="n in 5"
            :key="n"
            @click="() => {}"
          >
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
</template>

<script >
import axios from '../axiosConfig';

const isProd = 'production'==process.env.NODE_ENV;

export default{
  // beforeMount(){
  //   // console.log(this.photoImg, this.initname)
  //   console.log(process.env)
  // },
  computed:{
   isProd(){
    return isProd;
      // return 'production'==process.env.NODE_ENV
   },

    photoImg(){
      try{
      return this.$store.state.user.user.photoURL
      }catch(err){
        err
      }
      return null
    },
    initname(){
      // https://stackoverflow.com/a/63763497
      const name = this.$store.state.user.user.displayName
      if(name)
return name.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase()

      return null
    }
  },
  mounted(){
    console.log("mam", this.isProd, isProd)
  },


  methods:{

isDev(){
return process.env.NODE_ENV=='development'
},

  test(){
    console.log(this.$store.state.user)
    const token = this.$store.state.user.idtoken;
      const params = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
              }
              console.log("fixme!!")
    axios.post("/testAuth",{}, params).then(r=>{
      console.log(r)
    })

  },


  test2(){
    // console.log(this.$store.state.user)
    const token = this.$store.state.user.idtoken;

      const formData = new FormData()
      const {imgfile} = this.$store.state.create.input;
console.debug("test2", imgfile)
formData.append('image_file', imgfile)

      const params = {
        method:"POST"
        ,body: formData
        ,headers: {
          Accept:
"application/json, text/plain, */*",

// see: https://stackoverflow.com/a/49510941
// NOTE: **Very important!** Don't use your own content-type with fetch on multipart.
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      }


    // axios.post("/mask",formData, params)
    fetch("/api/mask", params)
    .then(async (r)=>{
      const j = await r.json()

      const w = j.imgsz[0];
      const h = j.imgsz[1];
      const normco = j.lines.map(c =>{
        return {x: c[0]/w, y: c[1]/h}
      })
      console.log(j, normco)
      this.$store.dispatch("setCoords", normco)
    }).catch(err=>{
      console.error(err)
    })

    // const options  = {
    //   method: "POST",
    //   mode: "cors",
    //   ...params,
    //   body: formData
    // }
    // fetch("http://localhost:7080", options).then(async (resp)=>{
    //   console.log(resp)
    // }).catch(err =>{
    //   console.error(err)
    // })

  },




  }// method

}

</script>
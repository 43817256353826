<template>
  <div class="text-center">
    <!-- <v-btn
      dark
      color="orange darken-2"
      @click="snackbar = true"
    >
      Open Snackbar
    </v-btn> -->

    <v-snackbar
      v-model="snackbar.showing"
      :timeout="timeout"
      :key="snackbar.text"
          :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="()=>{$store.dispatch('clearMessage');}"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'

  export default {
    data: () => ({
    //   snackbar: false,
    //   text: 'My timeout is set to 2000.',
      // timeout: 2000,
    }),
    // watch: {
    //     text:{
    //         handler: function(newVal){
    //             console.log(newVal)
    //         if(null!=this.$store.getters.snackbarMsg)
    //             this.snackbar = true
    //         }
    //     },
    // },
    computed: {
      timeout(){
        try{
        const msg = this.$store.state.snackbar.text
// console.log(500*msg.split(" ").length)
        return 2000 + 500*msg.split(" ").length
        }catch(err){
          err

        }
        return 2000
      },
        ...mapState(["snackbar"]),
        // snackbar(){
        //     return this.$store.getters.snackbarMsg
        //     // if(null!=this.$store.getters.snackbarMsg)
        //     // this.lsnackbar = true
        // },
        // text(){
        //     console.log(this.$store)
        //     return this.$store.getters.snackbarMsg
        // } 

    }
    
  }
</script>
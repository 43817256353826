<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 481.758 481.758" style="enable-background:new 0 0 481.758 481.758;" xml:space="preserve"
     role="img"
     >
<path d="M479.832,173.064c-5.238-10.529-51.881-103.35-76.299-119.953c-23.49-15.975-81.035-40.607-83.474-41.648l-2.809-1.199
	l-3,0.575c-0.486,0.093-48.893,9.319-73.372,9.319c-24.46,0-72.885-9.226-73.371-9.319l-3-0.575l-2.809,1.199
	c-2.439,1.041-59.984,25.673-83.475,41.647C53.807,69.715,7.164,162.535,1.926,173.064L0,176.935l1.501,4.054
	c0.628,1.698,16.18,41.7,84.723,50.834l5.941,0.792l21.05-29.278c9.469,54.129-4.04,181.589-20.762,242.944l-2.404,8.822
	l8.573,3.182c1.453,0.54,36.801,13.209,142.257,13.209c105.457,0,140.803-12.669,142.256-13.209l8.573-3.182l-2.404-8.822
	c-16.721-61.357-30.229-188.816-20.761-242.943l21.049,29.277l5.941-0.792c68.543-9.134,84.096-49.135,84.724-50.834l1.501-4.054
	L479.832,173.064z M306.426,32.574c-0.062,21.346-3.04,67.928-26.285,92.864c-10.243,10.988-23.086,16.329-39.262,16.329
	c-16.176,0-29.019-5.341-39.262-16.329c-23.246-24.938-26.223-71.522-26.285-92.864c16.49,2.85,46.717,7.584,65.547,7.584
	S289.936,35.424,306.426,32.574z M449.647,189.089c-8.602,7.68-24.27,17.473-50.885,22.019l-35.354-49.177l-7.459,14.588
	c-7.189,14.061-14.467,47.952-8.305,129.557c3.671,48.618,11.303,100.419,19.876,135.773c-15.403,3.477-53.097,9.644-126.641,9.644
	c-66.914,0-108.029-5.398-126.654-9.591c8.579-35.354,16.217-87.184,19.89-135.826c6.162-81.604-1.115-115.496-8.305-129.557
	l-7.459-14.588L82.994,211.11c-40.559-6.89-56.063-25.702-60.913-33.625c19.342-38.317,52.823-97.93,67.39-107.835
	c15.104-10.271,47.203-25.19,65.851-33.536c0.375,25.295,4.826,74.13,31.632,102.925c14.04,15.082,32.184,22.729,53.926,22.729
	s39.886-7.647,53.926-22.729c26.807-28.794,31.257-77.629,31.633-102.925c18.647,8.347,50.746,23.266,65.85,33.537
	c14.556,9.897,48.027,69.486,67.375,107.807C457.92,180.256,454.789,184.496,449.647,189.089z"/>
    </svg>


</template>
<script>
import Vue from 'vue'

export default Vue.extend({
    name: "short-sleeve-icon"
})
</script>
export function basename(path:string) {
  return path.replace(/.*\//, '');
}

export function allowRetry(job: { status: string, created_at: Date }, waitMinutes=60): boolean {
    const { created_at, status } = job;
    // console.log(job)

    if (["submitted", "in-queue", "generating"].includes(status)
        // &&
        // false
    ) {
        const e = new Date(created_at).getTime()
        const now = Date.now()
        const diffMin = (now - e) / 1000 / 60;
        // console.log(e, now, diffMin, "min")
        if (diffMin > waitMinutes)
            return true;
    }

    return false;
}

export function allowDelete(job: { status: string, created_at: Date }, waitMinutes=60): boolean {
    // allow delete if not in image synthesis state
    const { created_at, status } = job;
    if(status=="completed")
        return true;
    if(allowRetry(job))
        return true;
    // if (["completed"].includes(status))
    //     return true;
    return false;
}


// https://medium.com/frontend-canteen/how-to-detect-file-type-using-javascript-251f67679035
function readBuffer(file, start = 0, end = 2) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.slice(start, end));
  });
}

function check(headers) {
  return (buffers, options = { offset: 0 }) =>
    headers.every(
      (header, index) => header === buffers[options.offset + index]
    );
}

const isPNG = check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
const isJPEG = check([0xff, 0xd8, 0xff]);

export async function getImageType(file){
  const buffers = await readBuffer(file, 0, 8) as any;
//   console.log(typeof(buffers), buffers, file.type)
  const uint8Array = new Uint8Array(buffers);


  if(isPNG(uint8Array)){
    return {isKnown:true, type: 'image/png'}
  }else if( isJPEG(uint8Array) ){
    return {isKnown:true, type: 'image/jpeg'}
  }

  return {isKnown:false, type: file.type}

}


<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 487.891 487.891" style="enable-background:new 0 0 487.891 487.891;" xml:space="preserve">
	<path d="M487.334,122.096c-1.9-8.1-7.9-13.2-13.3-17.3c-37.2-28.3-79.2-50.5-124.7-66c-0.4-0.2-0.8-0.3-1.3-0.5
		c-0.1,0-3.4-1.1-8-2.6c-3.4-1-5-1.5-5-1.5c-1.7-0.5-3.5-0.7-5.2-0.7c-9.1,0-16.9,6.6-18.5,15.7c-2,11.7-10.6,44.6-25.8,56.3
		c-12,9.3-25.7,14-40.8,14h-0.5c-0.1,0-0.1,0-0.2,0h-0.5c-15.1,0-28.9-4.7-40.8-14c-15.1-11.8-23.7-44.6-25.8-56.3
		c-1.6-9.1-9.3-15.7-18.5-15.7c-1.8,0-3.5,0.2-5.2,0.7c0,0-1.6,0.5-5,1.5c-4.6,1.4-7.8,2.5-7.9,2.6c-0.3,0.1-0.6,0.2-0.8,0.3
		c-45.8,15.3-88.6,38-127.1,67.4c-13,9.9-15.7,21-8.6,36c13.4,28.6,23.7,50.4,33.5,70.9c2,4.1,7.9,16.7,24.1,16.7c2.4,0,5-0.3,8-0.8
		c13.6-2.6,26.9-5.2,41-7.8c0.4-0.1,0.9-0.2,1.3-0.3v42.6c0,54,0,108.1,0,162.1c0,18.7,10.2,29,28.8,29c33.4,0,66.7,0,100.1,0h105.7
		c20.2,0,30-9.9,30-30.3v-203.5l1.6,0.3c13.6,2.6,26.9,5.1,40.2,7.6c3.3,0.6,6,0.9,8.6,0.9l0,0c16.1,0,22-12.5,24.2-17.2
		c11.6-24.4,22.2-46.9,32.5-68.7C486.234,137.396,489.134,130.096,487.334,122.096z M458.934,131.996
		c-10.3,21.8-20.9,44.3-32.5,68.7c-0.4,0.8-0.7,1.3-0.9,1.7c-0.6-0.1-1.4-0.2-2.5-0.4c-13.3-2.5-26.6-5.1-40.2-7.6l-17.6-3.3
		c-3.9-0.8-8,0.3-11.1,2.9c-3.1,2.6-4.9,6.4-4.9,10.4v219.8c0,1.4-0.1,2.4-0.1,3.2c-0.7,0.1-1.6,0.1-2.9,0.1h-105.7
		c-33.4,0-66.7,0-100.1,0c-0.7,0-1.3,0-1.8-0.1c0-0.5-0.1-1.2-0.1-2c0-54,0-108.1,0-162.1v-58.9c0-4-1.8-7.8-4.9-10.4
		c-3.1-2.6-7.2-3.6-11.1-2.9c-5.8,1.1-11.6,2.2-17.3,3.3c-14.1,2.7-27.5,5.2-41.1,7.9c-0.8,0.2-1.4,0.2-1.9,0.3
		c-0.2-0.3-0.4-0.7-0.6-1.2c-9.7-20.5-20-42.3-33.4-70.8c-0.4-0.8-0.6-1.4-0.8-1.9c0.4-0.3,0.8-0.7,1.4-1.1
		c36.3-27.7,76.7-49,119.9-63.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1,0,0.2-0.1c0.5-0.2,1.5-0.5,2.7-0.9c3.8,16,13.7,48.3,33.9,64
		c16.8,13.1,36.1,19.7,57.4,19.7h0.6h0.6c21.3,0,40.6-6.6,57.4-19.7c20.3-15.8,30.1-48,33.9-64c1.2,0.4,2.1,0.7,2.6,0.9
		c0.4,0.2,0.8,0.3,1.2,0.5c43,14.6,82.6,35.5,117.7,62.1c1.3,1,2.1,1.7,2.7,2.2C460.134,129.296,459.634,130.396,458.934,131.996z"
		/>
</svg>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
    name: "t-shirt-icon"
})
</script>
export default {
    choices: [
        "0.webp",
        "1.webp",
        "2.webp",
        "3.webp",
    ].map(e => require(`@/assets/models/${e}`)),
    choicesID: [], // not used for now
    bodypos: {
        top: [
            "shirt-svgrepo-com-long.svg",
            "shirt-svgrepo-com-plain.svg",
            "shirt-svgrepo-com.svg",
            "shirt-svgrepo-com-top.svg",
        ].map(e => require(`@/assets/top/${e}`)),
        bottom: [
            "chinos-pants-svgrepo-com.svg",
            "jeans-pants-svgrepo-com.svg",
            "long-pants-svgrepo-com.svg",
            "pants-svgrepo-com.svg",
        ].map(e => require(`@/assets/bottom/${e}`)),
    }
}
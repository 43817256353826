// import axios from "axios";
// const _baseENDP = "/api"
import { event as gtagevent } from 'vue-gtag'
import  axios  from "../../axiosConfig";
import { basename } from "@/utils";
// axios.defaults.baseURL = "/styl3me/us-central1"
import models from "@/models"

import { auth } from '../../fbconfig'

const AuthRefreshWaitDur = 10*60*1000; // 10minutes
const WAIT_SCHEDULE = [2, 5, 10, 60, 60, 60,5,5] // for prod
const minMaskAPIWaitDur = 2000; // ms
const StatusCheckTimeOutDur = 1; //min.
const StatusCheckWaitDur = 10000; // ms
const outputFormat =  "webp";

// axios.defaults.baseURL = 'https://api.example.com';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

interface Template {
  default: 0;
  selected: 0;
  choices: string[];
  // choices: [];
  choicesID: [];
  bodypos: null;
}

const FirstStatus = "generate";

// todo:
// resultimg and job overlap and should be combined?
// http://localhost:8081/result/:jobid
// data being pulled from both and is confusing

interface ResultImg {
  imgurl: null;
  status: string;//typeof FirstStatus|"submitted"|"in-queue"|"generating"|"completed" "download"
  // error-on-submit
  jobid: null;
}

interface UserInput {
  imgfile: File |null;
  hash: number | null;
  targetTemplate: string|null;
  garmentType: string|null;
  isTransparentBg: boolean;
  outputFormat: string;
  maskImg: File | null;
}

interface Job {
  jobid: null;
  inputimg: null;
  modelimg: null; // template
  resultimg: null;

  status: string;//typeof FirstStatus|"submitted"|"in-queue"|"generating"|"completed" "download"

}


function statusCheckTimedOut(date){
  const dp = new Date(date)
  dp.setMinutes(dp.getMinutes()+StatusCheckTimeOutDur)
  console.log(dp, new Date())
  return dp < (new Date())
}
function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default  {
  state: {
    images: {}, // image cache

    template: {default: 0, selected: 0, choices:[], choicesID:[], bodypos:null} as Template,

    input: {imgfile: null, targetTemplate: null, garmentType:null, isTransparentBg: false, outputFormat: outputFormat,
      hash: null,
  maskImg: null
    } as UserInput,

    waitingResp: false, // waiting for server response

    result: {imgurl:null, status:FirstStatus} as ResultImg,
    job: {} as Job,

    queue: [], // status check queue ids of settimeout

    resultList: [],
    refreshResultList: true, // refreshList

    cycle: false,
    cycleStart: null,

    canCreate: true,
  },
  getters: {
    getResultJob(state){
      console.log(state.job)
      return state.job
    },
    getResultInput(state){
      return state.job.garmentinput
    },
    getResultTargetModel(state){
      // console.log(state.job)
      // fixme: target_model
      return state.job.template
    },
    getResultImgOrNull(state, getters){
// console.log(getters.canDownload, state.result.imgurl, state.job.imgurl)
      if(getters.canDownload)
        return decodeURIComponent(state.result.imgurl)
        // return state.job.imgurl
    return null
    },
    getResultImgUrl: (state)=>(jobid)=> {
      // fixme: getimg filename
// console.log(jobid, state.result.imgurl, state.job.imgurl)
      if((jobid!=undefined)&&(jobid==state.job.jobid))
        return decodeURIComponent(state.job.imgurl)

      // dummy
      // return state.job.imgurl
      return decodeURIComponent(state.result.imgurl)
    },

    randTemplateId(state){
      return getRandomInt(state.template.choices.length)
    },

    defaultTemplateId(state) {
      return state.template.default;
    },
    getTemplateChoices(state) {
      return state.template.choices
    },

//     getIsTransparentBg(state) { 
//          return state.input.isTransparentBg
//  },

    getInputFile(state){return state.input.imgfile},

    getInputFileHash(state){return state.input.hash},

      canNotChange(state, getters){
        return getters.getResultStatus!="generate"
      },

    getResultStatus(state) {
      const s = state.result.status
      if(s=="completed")
        return "download"
      return s
    },
    submitted(state){
      return (state.result.status==FirstStatus)
    },
    isSubmitted(state){
      return (state.result.status!=FirstStatus)
    },
    canSubmit(state){
// todo: create delay for button to activate
      // let waited = false;
      // if(Date.now()>state.input.hash+minMaskAPIWaitDur)
      //   waited = true;
      // if(state.input.hash==null)
      //   waited = true;
      // console.log("cansub", Date.now(),state.input.hash+minMaskAPIWaitDur,state.input.hash , waited  )

      return (state.result.status==FirstStatus) 
      && (state.input.imgfile!=null)
      && (state.input.garmentType!=null)
      // && (Date.now()>state.input.hash+minMaskAPIWaitDur)
      // && waited
    },

    underReview(state){
      const { status } =  state.result
      return status == "pending-review"
    },

    canDownload(state,getters){
      const { status } =  state.result
      return status=="completed" || getters.underReview//status == "pending-review"
    },

    getResultList(state){return state.resultList;},
  },



  mutations: {
        setImage(state, {url, payload}) {
            // state.images[url]= payload
//             // must!!! be below not above! otherwise, component will not see chage
//             You are directly setting item with index, which can't be observed by Vue
// Try splicing the old item from array and pushing the new one.
            state.images = {...state.images, [url]: payload}
    
        },

    resetCreateView(state){
console.log("reset")
      state.input.imgfile = null;
      // commit('rmInputgarment')
      state.result.imgurl = null;
      state.result.jobid = null;
      state.result.status = FirstStatus

state.job.garmentinput = null;

      state.template =  {default: 0, selected: 0, choices:[], choicesID: [], bodypos:null} as Template;
    
      state.waitingResp = false;
    },

    setInput(state, {targetTemplate: usetemplate}){
      state.template.selected = usetemplate;

      const { choices, choicesID } = state.template;
console.log("set template", usetemplate, choices[usetemplate], choicesID[usetemplate])

      state.input.targetTemplate = basename(choices[usetemplate])

      // state.input.targetTemplate = choicesID[usetemplate] ; // not use for now
    },

    setInputgarment(state, file:File){
      state.input.imgfile = file
      state.input.hash =  Date.now();//`${file.size}-${file.lastModified}`
      console.log(state.result.status==FirstStatus, state.input.imgfile!=null)
    },

    setInputMask(state, {mask, hash, maskMethod}){
      if(state.input.hash == hash){
        state.input.hash = hash
      state.input.maskImg = mask;
      state.input.maskMethod = maskMethod;
      }else{
        state.input.maskImg = null;
        state.input.hash = null;
      }
    },

    rmInputgarment(state){
      state.input.imgfile = null;
    },

    setInputGarmentType(state, type:string|null){
      state.input.garmentType = type
    },

    setInputTransparent(state, value:boolean){
      // console.debug("set itbg", value)
      state.input.isTransparentBg = value;
    },
    setInputOutFormat(state, value:string){
      state.input.outputFormat = value;
    },
//     submit(state, dummy){
// state.result.imgurl = "models/depositphotos_9292076-stock-photo-fashion-model-pretty-woman-standing.jpg";
// state.result.status = "submitted"
//     },

    updateStatus(state, {status, imgurl}){
      state.result.status = status
      if(status=="completed"){
      state.result.imgurl = imgurl
      state.job.imgurl = imgurl
      }
      // state.result = {...state.result, status : newstatus} // this triggers auth token update.
      state.job.status = status
    },

    setStatus(state, {newstatus, imgurl, jobid, callback}){
      state.result.status = newstatus
      state.result.jobid = jobid

      if(newstatus=="submitted")
        state.cycleStart = new Date()

      if(newstatus==FirstStatus){
        state.cycleStart = null
        state.cycle = false;
      }else if(newstatus=="completed"){
        state.cycleStart = null
        state.cycle = false;
        state.result.imgurl = imgurl;
      }else{
      state.cycle = true;
      if(callback)
          callback()
      }
    },

    setQueueIds(state, qid){
      state.queue = qid;
    },
    clearAllStatusCheck(state){
      for(const i of state.queue){
        clearTimeout(i)
console.log("state, clear",i)
      } 
      state.queue = []
    },

    setJob(state, {data}){
// console.log(state)
      state.job = data

      // todo:
//      const {garmentinput} = data
//      if(garmentinput!=undefined)
//        fetch(garmentinput, {method: "GET", headers:{Accept:
//          "image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8"
//// 'image/*'
//}})
//        .then((res) => res.blob())
//        .then((blob) => {
//            // Read the Blob as DataURL using the FileReader API
//            const reader = new FileReader();
//            reader.onloadend = (e) => {
//
//            const b64img = e.target ? e.target.result : null;
//                console.log(b64img, reader.result);
//                state.job.garmentinput = b64img;
//                // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
//
//                // Convert to Base64 string
//                // const base64 = getBase64StringFromDataURL(reader.result);
//                // console.log(base64);
//                // Logs wL2dvYWwgbW9yZ...
//          // worker.postMessage({method: "newimg", b64img, hash})
//            };
//            reader.readAsDataURL(blob);
//        });

      state.result = {imgurl: data.imgurl, status:data.status}
      // state.template = {default: 0, choices:[template]}
      // state.result = {imgurl:imgurl, status:"completed"}
    },

    // setResult(state, {garmentinput, template, imgurl, }){
    //   state.input.imgfile = garmentinput // url
    //   state.template = {default: 0, choices:[template]}
    //   state.result = {imgurl:imgurl, status:"completed"}
    // },

    setResultList(state, {jobs}){
      state.resultList = jobs
      state.refreshResultList = false;

    },
    updateResultListJob(state, job){
      console.log('up', job)
      if(state.resultList.length){
      const upl = state.resultList.map(e => {
        if(e.id==job.id){
          return {...e, ...job}
        }
        return e
      });
      console.log(upl)
      state.resultList = upl

      }
    },

    deleteFromResultList(state, {jobid}){
console.log(jobid, state.resultList.filter(e=>e.id!=jobid))
      state.resultList = state.resultList.filter(e=>e.id!=jobid)
    },

    setCanCreate(state, canCreate){
      state.canCreate = canCreate
    },
    updateTitle(state, title){
      state.job.title = title
      // update title in job list
      state.resultList =state.resultList.map(j=>{
        if(j.id==state.job.jobid){
          j.title = title
        }
          return j
      })
    },

    setWaiting(state, boolstate:boolean){
      state.waitingResp = boolstate;
    },

  },// mutation

  actions: {

    setWaiting({commit}, val){
      commit('setWaiting', val    )
    },

    setCanCreate({state, commit}, canCreate=false){
      commit('setCanCreate', canCreate)
    },

    async getTemplate({state, commit}){

      return  await new Promise(
        ()=>
        setTimeout(
        ()=>{
        state.template.choices = models.choices;
        state.template.choicesID = models.choicesID;
        const i = getRandomInt(models.choices.length);
        state.template.default = i
        commit('setInput', {targetTemplate: i})
        state.template.bodypos = models.bodypos 
          return;
        }
,1000
      )
      )

// old, will make request
// this was used to `warmup` from cold start
//  no longer necessary
//      return await axios.get('/template').then(resp =>{
//        if(resp.status!=200)
//        throw Error(resp.toString())
//
//        const {choices, choicesID, bodypos } = resp.data
//        state.template.choices = choices;
//        state.template.choicesID = choicesID;
//        state.template.default = getRandomInt(choices.length)
//        state.template.bodypos = bodypos 
//        // state.template.choices = shuffle(resp.data.choices);
//        // console.log(state.template.default)
//      return choices.length;
//      })

    },

    setTemplate({commit}, usetemplate:number){
      commit("setInput", {targetTemplate: usetemplate})
    },

    setInputGarmentType({commit}, type:string|null){
      commit("setInputGarmentType", type)
    },

    setInputgarment({commit}, file:File){
      commit("setInputgarment", file)
    },
    rmInputgarment({commit}){
      commit("rmInputgarment")
    },

    updateStatus({commit}, data){
      commit('updateStatus', data)
    },

    checkStatus({state, commit, rootState, dispatch}, payload): void {
      const jobid = state.result.jobid
      // jobid: "tOUaxeesU5fKAEHybAFB"
      const token = rootState.user.idtoken;
      const params = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.post(`/status`,{jobid},params).then(resp =>{
        if(resp.status!=200)
        throw Error(resp.toString())

        console.log(resp.data)
        const {status,imgurl } = resp.data

        const {id, title, updated_at} = resp.data;
commit('updateResultListJob',{id, title, updated_at, status})

        commit('setStatus', {newstatus:status, imgurl, jobid, callback: ()=>dispatch("contCheckStatus") })
        // commit("setStatus", {newstatus:status})
        // if(status=="completed")
        //   state.cycle = false;
      })
    },

    contCheckStatus({state, rootState, dispatch}){
        setTimeout(()=>{
console.debug(statusCheckTimedOut(state.cycleStart), state.cycleStart, )
          if(!statusCheckTimedOut(state.cycleStart))
            dispatch("checkStatus", {})
        },StatusCheckWaitDur)
    },


    loopCheckStatus({state, commit, rootState,dispatch}){

      const jobid = state.result.jobid
       const token = rootState.user.idtoken;
      const params = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }     

      let cumWait = 0;
      // const qid: ReturnType<typeof setTimeout>|null[] = []
      const qid: any[] = []
      // const start = Date.now()
      for (const wait of WAIT_SCHEDULE) {
        cumWait += wait;

        const cid: ReturnType<typeof setTimeout> = setTimeout(() =>
          axios.post(`/status`, { jobid }, params).then(resp => {
            if (resp.status != 200)
              throw Error(resp.toString())

        const {status,imgurl } = resp.data
        const {id, title, updated_at} = resp.data;

commit('updateResultListJob',{id, title, updated_at, status})

commit('setStatus', {newstatus:status, imgurl, jobid})

//                 const {data} = resp
//                 const {status, updated_at} = data;
// const timestamp = Date.parse(updated_at)//'2023-06-13T08:34:59.163Z'
//             console.log(wait, Date.now(), status, updated_at, timestamp)
//                 if(status!=undefined){
//                 this.$store.dispatch('updateStatus', status)
//                 }
// if(status=="error" && (timestamp > this.requestStart)){
//         this.clearAllStatusCheck()
// }
// else 
if(["error", "completed", "pending-review"].includes(status))
{ 
commit('clearAllStatusCheck')
console.warn("stopping")
 }

          }).catch(err => {
            console.error(wait, err)
commit('clearAllStatusCheck')
            // this.clearAllStatusCheck()
          }),
          cumWait * 1000
        );
          qid.push(cid)

console.log("waiting", wait, cumWait, cid)
      }

commit('setQueueIds', qid)



    },

    create({state, commit, rootState, dispatch}, payload): void {
      // const params = {...state.input}
    const token = rootState.user.idtoken;
      const params = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
              }

      const {title} = payload
      const formData = new FormData()
      formData.append('image', state.input.imgfile)
      const cos = JSON.stringify(rootState.mask.perimeter.map(e=> [e.x,e.y] ))
      formData.append('maskCo', cos)
      formData.append("maskSrc", rootState.mask.source)

      formData.append('garmentType', state.input.garmentType)

      const tgm = state.input.targetTemplate;//.split(/\./,1)[0]
      // const tgm = "mygmmodel"

      // const tgm = decodeURIComponent(state.input.targetTemplate).split("/").reverse()[0] // just basename
console.log(tgm,cos, token)
      formData.append('target_model', tgm)
      formData.append('title', title)
      formData.append('outputFormat', state.input.outputFormat)
      formData.append('isTransparentBg', state.input.isTransparentBg)

      if(state.input.maskImg) { 
        formData.append('maskimage', state.input.maskImg)
        formData.append('maskMethod', state.input.maskMethod)
 }

gtagevent('submit_job', {garmentType: state.input.garmentType,
  hasMask: state.input.maskImg !=null
} )

      axios.post(`/create`, formData, params).then(resp =>{

commit("setWaiting", false)

        if(resp.status!=200)
        throw Error(resp.toString())

        console.log(resp.data)
        const { jobid } = resp.data
       
        commit('setMessage', {msg: "submitted! Generation can take a few minutes."})
        // commit('setStatus', {newstatus:"submitted", jobid, callback: ()=>dispatch("contCheckStatus") })

        commit('setStatus', {newstatus:"submitted", jobid, callback: ()=>dispatch("loopCheckStatus") })

          // dispatch("contCheckStatus")

      }).catch(err=>{
        console.error(err)
        err.status = err.response.status

        let msg = ""
        if(err.status>=200){
          msg = "submitted"
        }else if(err.status==401){
          msg = "Not authorized. Authorization Token has expired. Try refreshing page."
        }else if(err.status>=400){
          msg = "submitted"
        }
        else{
          const {data} = err.response;
          console.log(data.message)
            msg = data.message ? data.message : err.string();
// msg = err.toString()
// commit("setWaiting", false)
        }
    
        commit('setStatus', {newstatus:FirstStatus })
        commit('setMessage', {msg: msg, color:'red'})
commit("setWaiting", false)
      });

      state.refreshResultList = true;

    },


    getResultOrReset({state, commit,dispatch, rootState, getters},payload:string|null): void {

        commit("resetCreateView")

      if((payload!=undefined)&&(payload!="")){

            // const token = await 
            (getters["user/getToken"]).then(token=>{

            if (token == null) {
                throw new Error("token cannot be null")
            }
    // const token = rootState.user.idtoken;
    // const token = getters["user/getToken"];

      const params = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
      }
      const jobid = payload // jobid

      // axios.get(`/result`, { params: { jobid: uid } }).then(resp =>{
      axios.get(`/result/${jobid}`,params).then(resp =>{
// console.log(`/result/${jobid}`, resp.data)

        // commit('setResult', resp.data)
        commit('setJob', {data:resp.data})

     const {garmentinput} = resp.data
     if(garmentinput!=undefined && garmentinput!=null && garmentinput!="undefined") { 
// console.log("fi", garmentinput, "type",typeof(garmentinput))

      dispatch("fetchImage", garmentinput)
 }


      })

            })

      }else{
        // reset values 
        commit("resetCreateView")
      }

    },

    async getResults({state, commit, rootState}) {

if(rootState.user.auth_updated_at+AuthRefreshWaitDur < Date.now())
    await auth.currentUser?.getIdToken( true)
    .then(function(idtoken){
      rootState.user.idtoken = idtoken;
// console.log("refreshed token", idtoken)
rootState.auth_updated_at = Date.now()

    }).catch(function(error){
      console.error(error)

    })

//fixme fake delay
await new Promise(
    (resolve)=>
setTimeout(resolve, 2000)
)
    const token = rootState.user.idtoken;


      const params = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
      }
      return axios.get(`/result`, params).then(resp =>{
        console.log(resp.data)
        const {jobs} = resp.data || [];
        commit('setResultList', {jobs})

        state.refreshResultList = false;

        return true
      }).catch(err=>{
        console.error(err)
        commit('setResultList', {jobs:[]})
        if(err.name=='AxiosError')
          commit('setMessage', {msg: 'Error occurred trying to get results. Please try again later', color:'red'})
      })
    },

    deleteJob({state, commit, rootState}, jobid:string): void {
    const token = rootState.user.idtoken;
      const params = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
      }
      axios.delete(`/delete/${jobid}`, params).then(resp =>{
console.log("delete", resp.data, resp.status)
        // const {jobid} = resp.data ;
        commit('deleteFromResultList', {jobid})
        commit('setMessage', {msg:'deleted'})
      })
    },

    // async cacheJob({commit}, uid:string){
    //   // if job id is present in result list then copy over value to state.job to prewarm resutls

    //     await commit("resetCreateView")
    //     // commit('setJob', resp.data)

    //   // return Promistrue;
    // },

    updateTitle({commit}, title:string): void {
      commit('updateTitle', title)
    },

        fetchImage ({state, commit}, url) {
        if (typeof state.images[url] !== 'undefined') {
            return null
        }

        commit('setImage', {
            url,
            payload: null
        })

        axios.get(url, {responseType: 'arraybuffer',
        headers:{
          Accept: "image/png,image/jpeg,image/webp"
        }
      }).then(response=>{
          console.log(response);
          const FR = new FileReader();
          FR.onload = (e)=>{
            // console.log(e.target?.result)

        commit('setImage', {
            url,
            payload: e.target?.result
        })
            
          }

          FR.readAsDataURL(new Blob([response.data],{type: response.headers['content-type']}))
        })

        // return requests.get(url, { responseType: 'arraybuffer'}).then(response => {
        //     commit('setImage', {
        //         url,
        //         payload: `data:${response.headers['content-type']};base64,${Buffer.from(response.data, 'binary').toString('base64')}`
        //     })
        // })
    }
  },

}// export
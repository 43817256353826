<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 481.092 481.092" style="enable-background:new 0 0 481.092 481.092;" xml:space="preserve">
<path d="M480.507,429.574c-0.074-0.838-7.563-84.81-20.797-171.441c-7.798-51.054-16.046-93.095-24.515-124.953
	c-15.036-56.565-27.981-70.312-36.411-75.731c-18.156-11.673-56.269-29.266-72.943-36.787c-0.029-0.521-0.054-0.88-0.065-1.043
	l-1.944,0.139c-3.411-1.532-5.592-2.493-6.018-2.681l-2.852-1.254l-3.059,0.586c-0.473,0.091-47.558,9.064-71.357,9.064
	c-23.78,0-70.885-8.974-71.357-9.064l-3.06-0.586l-2.851,1.254c-0.426,0.188-2.607,1.149-6.019,2.681l-1.942-0.139
	c-0.012,0.163-0.036,0.521-0.066,1.043c-16.674,7.521-54.787,25.113-72.943,36.787c-14.41,9.265-36.405,40.149-60.926,200.685
	C8.148,344.764,0.659,428.736,0.585,429.574L0,436.183l5.863,3.107c0.851,0.451,21.11,11.039,45.741,11.039
	c7.869,0,15.421-1.086,22.446-3.227l6.145-1.873l0.852-6.367c9.306-69.612,25.006-170.381,37.622-221.576
	c1.671,18.276,1.756,45.072-1.104,81.887c-3.997,51.441-12.73,108.193-21.731,141.22l-2.404,8.823l8.573,3.182
	c1.416,0.526,35.861,12.872,138.544,12.872s137.128-12.346,138.544-12.872l8.573-3.182l-2.404-8.823
	c-9.001-33.027-17.734-89.779-21.731-141.22c-2.86-36.814-2.775-63.61-1.104-81.887c12.616,51.194,28.316,151.962,37.622,221.576
	l0.852,6.367l6.144,1.873c7.025,2.141,14.577,3.227,22.446,3.227c24.633,0,44.892-10.588,45.742-11.039l5.862-3.107L480.507,429.574
	z M306.014,37.805c-0.587,23.074-4.814,64.357-26.206,87.306c-10.243,10.988-23.086,16.329-39.262,16.329
	s-29.019-5.341-39.262-16.329c-21.394-22.95-25.619-64.235-26.205-87.306c15.619,2.73,46.481,7.668,65.467,7.668
	S290.395,40.536,306.014,37.805z M429.487,430.329c-3.642,0-7.168-0.303-10.543-0.904c-2.306-16.759-8.683-62.175-16.458-109.527
	c-20-121.792-29.589-137.562-32.74-142.744l-7.896-12.986l-8.801,12.39c-11.43,16.091-14.613,57.866-9.462,124.164
	c3.722,47.894,11.516,100.495,19.903,135.273c-18.271,4.078-58.188,9.273-122.945,9.273c-64.796,0-104.722-5.202-122.946-9.269
	c8.388-34.778,16.183-87.384,19.904-135.278c5.151-66.298,1.968-108.073-9.462-124.164l-8.801-12.391l-7.896,12.986
	c-3.15,5.182-12.738,20.951-32.738,142.744c-7.776,47.352-14.153,92.768-16.459,109.527c-3.375,0.601-6.901,0.904-10.543,0.904
	c-12.498,0-23.852-3.543-30.437-6.11c2.09-21.982,9.068-91.602,19.984-163.066C64.613,107.556,85.313,79.293,93.124,74.272
	c14.415-9.268,43.697-23.165,62.058-31.595c1.094,26.516,6.898,69.673,31.439,96.034c14.04,15.082,32.184,22.73,53.926,22.73
	s39.886-7.647,53.926-22.73c24.541-26.361,30.346-69.519,31.438-96.034c18.358,8.428,47.636,22.323,62.058,31.595
	c7.809,5.02,28.503,33.271,51.959,186.794c10.92,71.472,17.905,141.155,19.997,163.152
	C453.333,426.788,441.982,430.329,429.487,430.329z"/>
	</svg>

</template>
<script>
import Vue from 'vue'

export default Vue.extend({
    name: "long-sleeve-icon"
})
</script>
<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 471.024 471.024" style="enable-background:new 0 0 471.024 471.024;" xml:space="preserve">
<path d="M363.938,332.806c-4.642-18.274-8.65-34.056-9.907-47.77c-3.532-38.53-0.744-76.952,9.323-128.475l1.293-6.615l-5.647-3.68
	c-24.467-15.947-34.517-48.854-36.493-56.167l9.066-88.05L311.678,0l-9.004,87.449c-4.808,7.037-26.235,34.555-67.162,34.555
	c-41.142,0-62.352-27.431-67.159-34.541L159.347,0l-19.895,2.049l9.067,88.063c-1.958,7.328-11.897,40.124-36.494,56.155
	l-5.646,3.68l1.293,6.615c10.066,51.523,12.854,89.945,9.322,128.475c-1.257,13.714-5.266,29.496-9.907,47.77
	c-7.893,31.07-17.714,69.737-17.714,115.728v8.1l7.923,1.682c2.444,0.519,60.751,12.709,138.215,12.709h0.003
	c77.464,0,135.771-12.19,138.215-12.709l7.923-1.682v-8.1C381.652,402.543,371.83,363.875,363.938,332.806z M235.514,451.024h-0.003
	c-58.995,0-107.537-7.469-126.029-10.754c1.05-39.789,9.488-73.011,16.989-102.54c4.85-19.094,9.039-35.584,10.439-50.868
	c3.568-38.917,1.06-77.452-8.399-127.819c17.523-13.686,28.051-33.304,33.856-47.604c13.119,13.405,36.977,30.566,73.144,30.566
	c36.166,0,60.026-17.161,73.146-30.566c5.805,14.301,16.331,33.918,33.856,47.605c-9.459,50.366-11.967,88.901-8.4,127.818
	c1.401,15.285,5.59,31.774,10.44,50.869c7.501,29.53,15.939,62.751,16.989,102.54C343.046,443.556,294.504,451.024,235.514,451.024z
	"/>
    </svg>

</template>
<script>
import Vue from 'vue'

export default Vue.extend({
    name: "tank-top-icon"
})
</script>
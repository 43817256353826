// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {getFirestore, connectFirestoreEmulator, FieldValue} from "firebase/firestore"

import {getStorage, connectStorageEmulator} from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBfH3qlHeS3JDlESlQ2hF-rgEFnLDCvb7Q",
//   authDomain: "styl3me.firebaseapp.com",
//   projectId: "styl3me",
//   storageBucket: "styl3me.appspot.com",
//   messagingSenderId: "730971294350",
//   appId: "1:730971294350:web:262655e5c1b0c1a97b57df",
//   measurementId: "G-X55CBXF0DH"
// };

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// landing
// const firebaseConfig = {
//   apiKey: "AIzaSyBMDT0PSo02R1Xyee7CTB78NrM4mco_86s",
//   authDomain: "styl3to.firebaseapp.com",
//   projectId: "styl3to",
//   storageBucket: "styl3to.appspot.com",
//   messagingSenderId: "78212165219",
//   appId: "1:78212165219:web:adfcbebe5bc592dd0d1256",
//   measurementId: "G-VJT8C6Q24J"
// };

// web-app
const firebaseConfig = {
  apiKey: "AIzaSyBMDT0PSo02R1Xyee7CTB78NrM4mco_86s",
  authDomain: "styl3to.firebaseapp.com",
  projectId: "styl3to",
  storageBucket: "styl3to.appspot.com",
  messagingSenderId: "78212165219",
  appId: "1:78212165219:web:c095689a881b47840d1256",
  measurementId: "G-BSBD9MP52D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

// Property 'serverTimestamp' does not exist on type 'typeof FieldValue'.
// const { serverTimestamp } = FieldValue;//.serverTimestamp

// If on localhost, use all firebase services locally
if (location.hostname === 'localhost') {
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth, "http://localhost:9099");
    // add more services as described in the docs: https://firebase.google.com/docs/emulator-suite/connect_firestore
    connectStorageEmulator(storage,'localhost',9199 )
}

export {auth, app, db, storage, firebaseConfig}
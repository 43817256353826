

// import { nextTick } from 'vue';
import MyAccountMenu from './components/MyAccountMenu.vue';
import SnackMessage from './components/SnackMessage.vue';
import DrawerMenu from './components/DrawerMenu.vue';

// /** loading overlay visibility */
// const loading: WritableComputedRef<boolean> = computed({
//   get: () => globalStore.loading,
//   set: v => globalStore.setLoading(v),
// });

  export default {
    // beforeMount(){
      // handled in router
    //     if(!this.$store.getters['user/isSignedIn'])
    //       this.$router.push({name: "signin"})
    // },
    data: () => ({
    landing : process.env.VUE_APP_LANDING_URL,
        drawer: null,
        showSignInBtn: false,
    }),
    computed:{
      isSignedIn(){
        return this.$store.getters['user/isSignedIn']
      },

    },
    methods:{
      mvDrawer(){
        if(this.isSignedIn){
          this.drawer != this.drawer
        }else{
          this.drawer = false;
        }
      }
    },
      mounted(){
        setTimeout(()=>{
          this.showSignInBtn = this.$route.name!='signin'
        },500)

      },

// watch:{
//   loading(){

//   async () => 
//   await nextTick()
// },
// },

    components: { MyAccountMenu, SnackMessage , DrawerMenu}
}

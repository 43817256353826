import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'
import Tshirt from '@/components/icons/Tshirt.vue'
import ShortSleeve from '@/components/icons/ShortSleeve.vue'
import LongSleeve from '@/components/icons/LongSleeve.vue'
import TankTop from '@/components/icons/TankTop.vue'
import SupportDiscord from '@/components/icons/SupportDiscord.vue'

Vue.use(Vuetify);


import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

Vue.use(LottieVuePlayer)

const opts = {
  theme: {
    themes: {
      light: {
        // primary: colors.orange.darken1, // #E53935
        primary: colors.grey.darken4, // #212121
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
    },

  },
    icons:{
      values: {
        tshirt: { component: Tshirt },
        shortsleeve: { component: ShortSleeve },
        longsleeve: { component: LongSleeve },
        tanktop: { component: TankTop },
        support: { component: SupportDiscord },
      }
    }
};


export default new Vuetify(opts);

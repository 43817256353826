import Vue from "vue";
import Vuex from "vuex";
import create from "./modules/create"
import user from "./modules/user"
import mask from "./modules/mask"
// import results from "./modules/results"

Vue.use(Vuex);

function LocalStorageGetOrDefault(key:string, ifnull:any){
  const v = localStorage.getItem(key)
  if(v){
    if(typeof ifnull == "boolean")
      return v.toLocaleLowerCase()=="true"
    return v as typeof ifnull;
  }
  return ifnull
}

export default new Vuex.Store({
  state: {
    limits:{
      uploadFileSizeLimitMB: 5
    },
    continueStatusCheck: false,
    imgHeight: window.innerHeight,

    snackbar: {showing: false, text: "test", color: "primary"},
    // snackbarmsg: null,
    dialogs:{
      // show or not
      notifyPayplan: true,
      flaggingExplain: true,
    }
  },
  getters: {
    getImgHeight(state){
      console.log("idxh", state.imgHeight)
      return 600;Math.min(1024, state.imgHeight)
    },

    snackbarMsg(state){
      console.log(state.snackbar.text)
      return state.snackbar.text
    }

  },
  mutations: {
    initializeLocalStore(state){
      // const v = localStorage.getItem('notifyPayplan')
      // state.dialogs.notifyPayplan = v ? v!='false': true;
state.dialogs.notifyPayplan = LocalStorageGetOrDefault('notifyPayplan', true)
state.dialogs.flaggingExplain = LocalStorageGetOrDefault('flaggingExplain', true)
      // const f = localStorage.getItem('flaggingExplain')
      // state.dialogs.notifyPayplan = f ? f!='false': true;
// console.log("ls init", state.dialogs)

    },
    setDialog(state, keyval){
      // state.dialogs.notifyPayplan = val
      state.dialogs = { ...state.dialogs, ...keyval } 
      for(const [k,v] of Object.entries(keyval))
        localStorage.setItem(k, (v as string).toString())
    },
    setImgHeight(state, h){state.imgHeight=h; console.log(h)},

    setMessage(state, {msg, color='info'}){
      state.snackbar.text = msg;
      state.snackbar.color = color;//||'info';
      state.snackbar.showing = true;
      // console.log(state.snackbar)
    },
    clearMessage(state){
      state.snackbar.text = ""; 
      state.snackbar.showing = false
    },
  },
  actions: {

    setImgHeight({commit}, height){
      commit("setImgHeight", height)
    },

    setMessage({commit}, payload){
      // console.log(payload)
      commit("setMessage", payload)
    },

    clearMessage({commit}, msg){commit("clearMessage")}

  },
  modules: {
    create,
    user,
    mask,
    // results
  },
});

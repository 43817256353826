import store from "@/store";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import {auth} from '../fbconfig'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [

  {
    path: "/signin",
    name: "signin",
    component: ()=> import("../views/SignInView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: ()=> import("../views/LogOut.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: ()=> import("../views/GuideView.vue"),
  },
  {
    path: "/create",
    name: "create",
    component: ()=> import("../views/CreateView.vue"),
    props:{title: 'create'}
  },
  {
    path: "/list",
    name: "list",
    component: ()=> import("../views/PhotoShootList.vue"),
    // props:{title: 'list'} // will show weird toolip on hover
  },
  {
    path: "/result/:uid",
    name: "result",
    component: ()=> import("../views/ResultView.vue"),
    props:{title: 'result'}
  },
  // {
  //   path: "/flag/:uid",
  //   name: "flag-label",
  //   component: ()=> import("../views/FlagView.vue"),
  // },

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },

  {
    path: "/",
    name: "home",
    // component: ()=> import('../views/HomeView.vue'),
    component: ()=>import('../views/MainView.vue'),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const getCurrentUser = ()=>{
  return new Promise((resolve, reject)=>{
    const removeListener = onAuthStateChanged(
      // getAuth(),
      auth,
      (user)=>{
        removeListener(),
        resolve(user);
      },
      reject
    );
  })
}

router.beforeEach(async (to,from, next)=>{
  const u = await getCurrentUser()
  store.dispatch('user/setUser', u)
  if(to.name!=='signin' && !u) next({name:"signin"})
  else next()

  // }else if(!from.path.includes("/signin")){
  //   console.log(to,from,from.path.includes("/signin"))
  // }
  // next()
  // already in signin

})

/*
router.beforeEach( async (to,from,next)=>{
  // if(to.matched.some(record=>record.meta.requiresAuth))
  const u = await getCurrentUser()
  console.log(u)
  // next()

  if(u){
    if (store.getters['user/user']) {
      console.log('force set user')
      store.dispatch('user/setUser', u)
    }

    if(to.path.includes("/signin")){
      console.log("already signed in", from)
      next(from.path)
    }else{
      next()
    }
  }else{
    console.log(to.path, from.path)
    next("/signin")
    // next({name: "signin"})
  }

  // if(to.path.includes("/signin")){
  //   // return;
  //   next();

  // }else if(await getCurrentUser()){
  //   next();
  // }else{
  //   console.warn("not signed in")
  //   next("/signin")
  // }
})
*/

export default router;

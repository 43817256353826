import { auth } from '../../fbconfig'
import {
    // createUserWithEmailAndPassword,
    // getAuth,
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth'
import router from '@/router'

// TODO: https://firebase.google.com/docs/auth/admin/manage-sessions#detect_id_token_revocation
export default  {

    namespaced: true,
    state: {
        waiting: false,
        user: null,
        idtoken : null,
        auth_updated_at: null,
    },

    getters:{
        user(state){return state.user},
        isWaiting(state){return state.waiting},
        isSignedIn(state){return state.user!=null},

        // FIXME : necessary? seems null is pass as token to all request on timeoout?
        async getToken(state){
// console.log("gt", state.user)
            if(state.user){
                return await state.user.getIdToken({forceRefresh: true}).then(function (idToken) {
                    // Send token to your backend via HTTPS
                    // ...
// console.log('got token',idToken)
                    // Once you have an ID token, you can send that JWT to your backend and validate it using the Firebase Admin SDK, or using a third-party JWT library if your server is written in a language which Firebase does not natively support.
                    state.idtoken = idToken
                    state.auth_updated_at = Date.now()

                    return idToken
                }).catch(function (error) {
                    // Handle error
                    console.error(error)
                });
            }
// console.log("skip??")
                return null
        }
    },
    mutations:{
        setUser(state, payload) {
            state.user = payload
            //Log out the user state
// console.debug('setuser',state.user)
            if (payload == null)
{ 
                state.idtoken = null

                    state.auth_updated_at = null;
                    state.user = null;
 }

            if(state.user)
                state.user.getIdToken({forceRefresh: true}).then(function (idToken) {
                    // Send token to your backend via HTTPS
                    // ...
// console.log(idToken)
                    // Once you have an ID token, you can send that JWT to your backend and validate it using the Firebase Admin SDK, or using a third-party JWT library if your server is written in a language which Firebase does not natively support.
                    state.idtoken = idToken

                    state.auth_updated_at = Date.now()

                }).catch(function (error) {
                    // Handle error
                    console.error(error)
                });

        },

        setStatus(state, isWaiting){
            // console.log("ss", isWaiting)
            state.waiting = isWaiting;
        }
    },
    actions:{
        // async signup(context, { email, password }){
        //     const response = await createUserWithEmailAndPassword(auth, email, password)
        //     if (response) {
        //         context.commit('setUser', response.user)
        //     } else {
        //         throw new Error('signup failed')
        //     }
        // },

        async login(context, { email, password }) {
            context.commit('setStatus', true)

            //fixme fake delay
            await new Promise(
                (resolve) =>
                    setTimeout(resolve, 2000)
            )

            // setTimeout(
            //     async ()=>{
            console.warn("fake signin delay")
            // const auth = getAuth();

            // const response = 
            return await signInWithEmailAndPassword(auth, email, password).then(response => {

                context.commit('setStatus', false)
                context.commit('setUser', response.user)
                context.commit("setMessage", { msg: "welcome!" }, { root: true })
                return response
            }).catch(err => {

                context.commit('setStatus', false)
                context.commit("setMessage", { msg: "login failed", color: "red" }, { root: true })
                throw new Error('login failed')
            })
            /*
context.commit('setStatus', false)
            if (response) {

                context.commit('setUser', response.user)
                context.commit("setMessage", "welcome!", {root: true})

        //  if(route.path!="/")
        //         router.push({name:"home"})
            } else {
                context.commit("setMessage", "login failed", {root: true})
                throw new Error('login failed')
            }
*/
            //     }
            //     ,2000
            // )

        },

        setUser({commit}, user){
            commit('setUser',user)
        },

        async logout(context){
context.commit('setStatus', true)
// const auth = getAuth();
console.log(auth, auth.signOut)

            await signOut(auth).then(r=>{
            context.commit('setUser', null)
context.commit('setStatus', false)
         if(router.currentRoute.name!="home")
                router.push({name:"home"})

            }).catch(err=>{
                console.error(err)
            })
        }
    }
}
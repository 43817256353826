import axios from "axios";
// const _baseENDP = "/api"

const instance = axios.create({
baseURL : 
"/api"
// "/styl3me/us-central1"
// "https://us-central1-styl3me.cloudfunctions.net"
})
// axios.defaults.baseURL = "/styl3me/us-central1"
// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

instance.defaults.timeout = 60*1000;
export default instance;


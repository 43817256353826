<script setup>
/* <script setup lang="ts"> */
// import type DrawerMenuItem from '@/interfaces/DrawerMenuItemInterface';
// console.log(process.env)

let yourDate = new Date()
const buildat = yourDate.toISOString().split(".",2)[0];

/** Drawer menu items */
// const items: DrawerMenuItem[] = [
const items = [
  {
    title: 'Home',
    icon: 'mdi-home',
    to: { name: 'home' },
  },
  {
    title: '-', // Divider
    icon: 'top'
  },
  {
    title: 'Guide',
    icon: 'mdi-navigation-outline',
    to: { name: 'guide' },
  },
  {
    title: 'Create',
    icon: '$tshirt',
    to: { name: 'create' },
  },
  {
    title: 'Job list',
    icon: 'mdi-format-list-bulleted-type',
    to: { name: 'list' },
  },
  //   {
  //     title: 'Setting',
  //     icon: 'mdi-cog',
  //     to: { name: 'setting' },
  //   },

    // {
    //   title: 'About',
    //   icon: 'mdi-information',
    //   to: { name: 'about' },
    // },

  //   {
  //     title: 'Disabled Item',
  //     icon: 'mdi-cancel',
  //     // empty `to` value becomes to disabled item
  //   },
  {
    title: "Support",
    icon: '$support',
    // href: "https://discord.gg/tfsfnwhNU"
    href: process.env.VUE_APP_DISCORD_INVITE
  },
  
  {
    title: '-', // Divider
    icon: 'bottom'
  },
  {
    title: 'logout',
    icon: 'mdi-exit-to-app',
    to: { name: 'logout' },
  },
];
</script>

<template>
  <v-list nav>
    <v-container v-for="item in items" :key="`${item.title}-${item.icon}`"
    >
      <v-divider v-if="item.title === '-'" />
      <template v-else>
        <!-- Menu Item -->
        <v-list-item v-if="!item.items" :disabled="!item.to && !item.href" :to="item.to" 
        :href="item.href"
       :target="item.href ? '_blank' : 'false'" 
        link>
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Sub menu -->
        <!-- <v-list-group v-else-if="item.items" v-model="item.active">
          <template #activator="{ props }">
            <v-list-item v-bind="props" :prepend-icon="item.icon" :title="item.title" />
          </template>  -->

          <!-- Sub menu item -->
          <!-- <v-container v-for="subItem in item.items" :key="subItem.title">
            <v-divider v-if="subItem.title === '-'" />
            <v-list-item v-else :disabled="!subItem.to" :to="subItem.to" link>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-container>
        </v-list-group>
         -->

      </template>
    </v-container>

    <!-- show version at bottom -->
    <v-col style="position: absolute; bottom:7vh; max-width: 90%;">
        <!-- <v-list-item >
          <v-list-item-icon>
            <v-icon v-text="question"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            discord
          </v-list-item-content>
        </v-list-item> -->


    <v-divider  />

        <v-list-item >
          <v-list-item-content>

            <caption>
            ver. {{require('../../package.json').version}}
            </caption>
            <p class="caption text-medium-emphasis">
    beta
    </p>
            <p class="caption text-medium-emphasis">
{{buildat}}
    </p>

            <!-- <v-list-item-title v-text="`ver. ${require('../../package.json').version}`"></v-list-item-title> -->
          </v-list-item-content>
        </v-list-item>
    </v-col>

  </v-list>
</template>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "@/plugins/vuetify"



const gtag = "G-HM0ZZQLC85" // web-app in ga4
// import { firebaseConfig } from "./fbconfig";
// const gtag = firebaseConfig.measurementId;

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  
    pageTrackerTemplate(to) {
      console.log(to)
      // dont want analytics to be *polluted* with /result/xyz...
      if(to.name=="result") return {page_title: "result_jobid", page_path: '/result/jobid'}
    return {
      page_title: to.name,
      page_path: to.path
    }
  },
  config: { id: gtag }
}, router);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  beforeCreate(){
    this.$store.commit('initializeLocalStore')
  },
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

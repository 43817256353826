
interface Coord {
    x: number
    y: number
}

// interface Coords extends Array<Coord>{};
type Coords = Coord[];

export default {
    state:{
        perimeter:  [] as Coords,
        // perimeter: Coord[], //Array<Coord> = [],
        completed: false,
        redrawInc: 0,
        source: "manual"
    },
    getters: {

    },
    mutations: {
        pushCoord(state, co:Coord){
            const pre = state.perimeter
// BUG: there is some bug that pushes/initializes perimeter with non-normalized coordinates
            const clean = pre.filter(c=>{
                return c.x<=1 && c.y<=1
                // keep only valid  values 
            })
// console.debug("pre+",pre.length, co, clean)
            state.perimeter = clean
            state.perimeter.push(co)
// console.debug("+",state.perimeter.length)
        },



        clearCoords(state){
            state.perimeter = [] as Coords;
        state.source= "manual";
        },

        setMaskDone(state, b:boolean){
            state.completed = b
        },
        redraw(state){
            state.redrawInc +=1
        }
    },
    actions: {
        redraw({commit}){
            commit('redraw')
        },
        pushCoord({commit}, co:Coord){
            commit('pushCoord', co)
        },

        clearCoords({commit}){
            commit('clearCoords')
        },
        setMaskDone({commit}, b:boolean){
            commit('setMaskDone', b)
        },

        setCoords({state, commit}, coords:Coords){

            commit('clearCoords')

            for(const co of coords){
                commit('pushCoord',co)
            }

            state.source = "api"
            state.completed = true;
            // commit('setMaskDone', true)

            commit('redraw')
        },
    }
}